import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ExternalButtonLink } from "components/buttons"

const StyledShareOurVision = styled.section`
  padding: 68px 0;
  background-color: ${colors.purpleDark};
  color: ${colors.white};
  text-align: center;

  ${breakpoint.medium`
    padding: 144px 0;
  `}

  h2 {
    color: ${colors.white};
    padding-bottom: 2rem;    
  }

  p {
    max-width: 827px;
    margin: 0 auto 32px auto;

    ${breakpoint.medium`
      margin-bottom: 50px;
    `}
  }

  a {
    width: auto;

    &:hover {
      background-color: ${colors.charcoal};
    }
  }
`

const ShareOurVision = () => {
  return (
    <StyledShareOurVision>
      <Container data-aos="fade-up">
        <h2>Share Our Vision</h2>
        <p>
          At Visby, everyone’s voices and ideas are valued, because we know that
          our sum is only greater when each teammate’s whole self is brought to
          the table. If you’re ready to help usher in the future of diagnostics,
          check out our open roles today.
        </p>
        <ExternalButtonLink href="https://boards.greenhouse.io/visbymedical" target="_blank" rel="noopener noreferrer" backgroundcolor="primary">
          View Open Positions
        </ExternalButtonLink>
      </Container>
    </StyledShareOurVision>
  )
}

export default ShareOurVision