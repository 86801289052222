import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledTeam = styled.section`
  padding: 20px 0;
  background-color: ${colors.charcoal};
  border-bottom-width: 0;

  .title {
    margin-bottom: 60px;
    text-align: center;

    ${breakpoint.medium`
      margin-bottom: 90px;
    `}

    h2 {
      color: ${colors.white};
      padding-bottom: 2rem;
    }

    p {
      max-width: 730px;
      margin: 0 auto;
      color: ${colors.white};
    }
  }

  .team__grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    ${breakpoint.small`
      margin: 0 -20px;
    `}
  }

  .department {
    margin: 40px 0;
    color: ${colors.white};
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    ${breakpoint.medium`
      margin: 80px 0;    
    `}

    .department__title {
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 21px;
      
      ${breakpoint.medium`
        font-size: 24px;
      `}

    }
  }
  
.full_employee {
	padding: 20px; 
	width: 100%;
	
    ${breakpoint.xs`
       width: 50%;  
    `}
	
    ${breakpoint.small`
       padding: 10px;
		width: 33.3%;  
    `}

    ${breakpoint.medium`
       padding: 20px 0;
		width: 25%; 
    `}
	
	}

  .grid__item {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  .grid__item_modal {
	display: none;
    position: fixed;
    z-index: 20000;
    left: 0;
    top: 0;
    height: 100%;
    overflow: auto;
    width: 100%;
    background: ${colors.charcoal};
    
    &.active {
		display: flex;
	}
}
`
const StyledExecutive = styled.div`
  width: 100%;
  color: ${colors.white};
  text-align: left;

  .member-image {
    width: 100%;
  }

	.member-image-link {
		background: ${colors.white};
    	cursor: pointer;

		&:hover {
		  opacity: 0.6;
		  transition: all 0.3s;
		}
	}
	
	.member-image-link:hover + .member-details {
   		color: ${colors.orange};
	}

  .member-details {
    margin-top: 20px;
  	cursor: pointer;
    
    p {
      line-height: 22px;
      font-size: 16px;
    }
    .fullname {
      font-weight: 700;
      font-size: inherit;
      margin-bottom: 5px;
      text-align: left;
      
      ${breakpoint.medium`
        margin-bottom: 10px;
      `}
    }
    
      &:hover {
		  color: ${colors.orange};
		} 
  }
`


const StyledTeamMember = styled.div`
  width: 100%;
  color: ${colors.white};
  text-align: left;

  .member-image {
    width: 100%;
  }

  .member-details {
    margin-top: 20px;
    
    p {
      line-height: 22px;
      font-size: 16px;
    }
    .fullname {
      font-weight: 700;
      font-size: inherit;
      margin-bottom: 5px;
      text-align: left;
      
      ${breakpoint.medium`
        margin-bottom: 10px;
      `}
    }
 }
`


const StyledTeamMemberBio = styled.div`
width: 100%;
text-align: left;
display: flex;
margin: 100px auto;
flex-wrap: wrap;

	${breakpoint.small`
		flex-wrap: nowrap;
	`}

  .member-imagebio {
    width: 60%;
    max-width: 300px;
    height: fit-content;
	margin: 0px auto;

	${breakpoint.small`
		width: 35%;
		margin: 0 40px 40px 20%;
	`}
  }

	.member-imagebio img {
	  max-width: 300px;
	  max-height: 400px;
	}


  .member-detailsbio {
		width: 100%;
		margin: 0px auto;
		padding: 0 30px;
		text-align: center;
		
		  ${breakpoint.small`
		  		text-align: left;
		  		padding: 0px;
				width: 65%;
				margin: 0px 19% 0px 0px;
		  `}

		p {
		  line-height: 24px;
		  font-size: 16px;
	  
		  ${breakpoint.medium`
			  line-height: 26px;
			  font-size: 18px;
		  `}
		}
	
		.namebio {
		  font-weight: 700;
		  font-size: inherit;
		  margin-bottom: 5px;
		  margin-top: 40px;
		  font-size: 28px;

		  ${breakpoint.medium`
			margin-bottom: 10px;
			margin-top: 0px;
			font-size: 32px;
		  `}
		}
	
		.positionbio {
		  font-weight: 700;
		  font-size: inherit;
		  margin-bottom: 5px;
		  font-size: 20px;

		  ${breakpoint.medium`
			margin-bottom: 10px;
			font-size: 24px;
		  `}
		}
	}
  
	  .bio {
	  		padding-top: 40px;
	  		padding-bottom: 60px;
	  	
			a {
				color: ${colors.orange};

				&:hover,
				&.active {
				  color: ${colors.purpleDark};
				}
			}
	  }

	.closebtn {
	  position: absolute;
	  right: 8%;
	  top: 32px;
	  width: 32px;
	  height: 32px;
	  opacity: 1;
      cursor: pointer;
      
		  ${breakpoint.medium`
			  right: 18%;
		  `}
      
	}
	
	
	.closebtn:hover {
	  opacity: 0.4;
	  transition: all 0.5s ease-out 0s;
	}
	.closebtn:before, .closebtn:after {
	  position: absolute;
	  left: 15px;
	  content: ' ';
	  height: 33px;
	  width: 2px;
	  background-color: ${colors.white};
	  top: 0px;
	}
	.closebtn:before {
	  transform: rotate(45deg);
	}
	.closebtn:after {
	  transform: rotate(-45deg);
	}
	  
  `

const Executive = props => (
  <StyledExecutive>
    <div className="member-image-link"
      onClick={() => props.toggleFunction("bio", props.slug)}
      onKeyDown={() => props.toggleFunction("bio", props.slug)}
      role="button"
      tabIndex="-1"
      aria-label={props.name}>
      <GatsbyImage
        image={getImage(props.image)}
        alt={props.name}
        className="member-image"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
    </div>
    <div className="member-details"
      onClick={() => props.toggleFunction("bio", props.slug)}
      onKeyDown={() => props.toggleFunction("bio", props.slug)}
      role="button"
      tabIndex="-1"
      aria-label={props.name}>
      <p className="fullname">{props.name}</p>
      <p>{props.position}</p>
    </div>
  </StyledExecutive>
)

const TeamMember = props => (
  <StyledTeamMember>
	<div className="member-image-link">
  <GatsbyImage
      image={getImage(props.image)}
      alt={props.name}
      className="member-imagebio"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
    </div>
   	  <div className="member-details">
      <p className="fullname">{props.name}</p>
      <p>{props.position}</p>
    </div>
  </StyledTeamMember>
)

const TeamMemberBio = props => (
  <StyledTeamMemberBio>
    <div className="closebox">
      <button className="closebtn" aria-label="Close" tabIndex="0"
      onClick={() => props.toggleFunction("bio", props.slug)}></button>
      </div>
    <GatsbyImage
      image={getImage(props.image)}
      alt={props.name}
      className="member-imagebio"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
    <div className="member-detailsbio">
      <p className="namebio">{props.name}</p>
      <p className="positionbio">{props.position}</p>
      { props.bio ?
        (
          <div
            className="bio"
            dangerouslySetInnerHTML={{ __html: props.bio }}
          ></div>
        )
        :
        <p className="bio"><i>{props.name}'s bio will be soon published.</i></p> 
      }
    </div>
  </StyledTeamMemberBio>
)

const Team = props => {
  const data = useStaticQuery(graphql`
    query {
      allWpEmployee(sort: {fields: employees___order, order: ASC}) {
        nodes {
          id
          name: title
          slug
          employees {
            position
            department
            bio
            order
            profilePicture {
              localFile {
                childImageSharp {
                    gatsbyImageData 
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <StyledTeam>
      <Container data-aos="fade-up">
        <div className="department">
          <p className="department__title">Visby Leadership</p>
          <div className="team__grid">
            {data.allWpEmployee.nodes.map(employee => {
              if (employee.employees.department === "Visby Leadership") {
                return (
                  <div className="full_employee" key={employee.id}>
                    <div className="grid__item">
                      <Executive
                        key={employee.id}
                        name={employee.name}
                        position={employee.employees.position}
                        bio={employee.employees.bio}
                        order={employee.employees.order}
                        slug={employee.slug}
                        toggleFunction={props.toggleFunction}
                        image={
                          employee.employees.profilePicture.localFile.childImageSharp.gatsbyImageData
                        }
                      />
                    </div>
                    <div className="grid__item_modal" data-filter data-filter-type="bio" data-target={employee.slug}>
                      <TeamMemberBio
                        key={employee.id}
                        name={employee.name}
                        position={employee.employees.position}
                        bio={employee.employees.bio}
                        slug={employee.slug}
                        toggleFunction={props.toggleFunction}
                        image={
                          employee.employees.profilePicture.localFile.childImageSharp.gatsbyImageData
                        }
                      />
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>

        <div className="department">
          <p className="department__title">Board Members</p>
          <div className="team__grid">
            {data.allWpEmployee.nodes.map(employee => {
              if (employee.employees.department === "Board Members") {
                return (
                  <div className="full_employee" key={employee.id}>
                    <div className="grid__item">
                      <TeamMember
                        key={employee.id}
                        name={employee.name}
                        position={employee.employees.position}
                        order={employee.employees.order}
                        slug={employee.slug}
                        toggleFunction={props.toggleFunction}
                        image={
                          employee.employees.profilePicture.localFile.childImageSharp.gatsbyImageData
                        }
                      />
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>

        <div className="department">
          <p className="department__title">Board Observers</p>
          <div className="team__grid">
            {data.allWpEmployee.nodes.map(employee => {
              if (employee.employees.department === "Board Observers") {
                return (
                  <div className="full_employee" key={employee.id}>
                    <div className="grid__item">
                      <TeamMember
                        key={employee.id}
                        name={employee.name}
                        position={employee.employees.position}
                        order={employee.employees.order}
                        slug={employee.slug}
                        toggleFunction={props.toggleFunction}
                        image={
                          employee.employees.profilePicture.localFile.childImageSharp.gatsbyImageData
                        }
                      />
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>

        <div className="department">
          <p className="department__title">Advisor</p>
          <div className="team__grid">
            {data.allWpEmployee.nodes.map(employee => {
              if (employee.employees.department === "Advisor") {
                return (
                  <div className="full_employee" key={employee.id}>
                    <div className="grid__item">
                      <TeamMember
                        key={employee.id}
                        name={employee.name}
                        position={employee.employees.position}
                        order={employee.employees.order}
                        slug={employee.slug}
                        toggleFunction={props.toggleFunction}
                        image={
                          employee.employees.profilePicture.localFile.childImageSharp.gatsbyImageData
                        }
                      />
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>

        <div className="department">
          <p className="department__title">Scientific Advisory Board</p>
          <div className="team__grid">
            {data.allWpEmployee.nodes.map(employee => {
              if (employee.employees.department === "Scientific Advisory Board") {
                return (
                  <div className="full_employee" key={employee.id}>
                    <div className="grid__item">
                      <TeamMember
                        key={employee.id}
                        name={employee.name}
                        position={employee.employees.position}
                        order={employee.employees.order}
                        slug={employee.slug}
                        toggleFunction={props.toggleFunction}
                        image={
                          employee.employees.profilePicture.localFile.childImageSharp.gatsbyImageData
                        }
                      />
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>

        <div className="department">
          <p className="department__title">In memory of</p>
          <div className="team__grid">
            {data.allWpEmployee.nodes.map(employee => {
              if (employee.employees.department === "In Memory Of") {
                return (
                  <div className="full_employee" key={employee.id}>
                    <div className="grid__item">
                      <TeamMember
                        key={employee.id}
                        name={employee.name}
                        position={employee.employees.position}
                        order={employee.employees.order}
                        slug={employee.slug}
                        toggleFunction={props.toggleFunction}
                        image={
                          employee.employees.profilePicture.localFile.childImageSharp.gatsbyImageData
                        }
                      />
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>



      </Container>
    </StyledTeam>
  )
}

class Team1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "bio",
    }
  }

  openCloseModal = (type, value, event) => {

    const modalWindow = document.querySelectorAll(
      "[data-filter-type='" + type + "'][data-target='" + value + "']"
    )
    modalWindow.forEach(filter => {
      filter.classList.toggle("active")
    })
  }


  render = () => (
    <React.Fragment>
      <Team toggleFunction={this.openCloseModal} />
    </React.Fragment>
  )
}

export default Team1