import React from "react"

// Components
import Seo from "components/seo"
import Hero from "../components/hero"

// Page Sections
import Team from "./screens/our-team/team"
import ShareOurVision from "./screens/our-team/share-our-vision"

const OurTeam = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Meet Our Team">
        <p>
          We’re all about connecting the dots. From problem-solving to discovering new solutions, we pride ourselves on assembling a team that values curiosity,
          determination, and creative, empathic thinking.
        </p>
      </Hero>
      <Team />
      <ShareOurVision />
    </React.Fragment>
  )
}
export default OurTeam

export const Head = () => (
  <Seo
        title="Our Team"
        description="From problem-solving to discovering new solutions, we pride ourselves on assembling a team that values curiosity, determination, and creative, empathic thinking."
        image="/meta/our-team.jpg"
  />
)
